import React, { useState } from 'react'
import { Input } from 'antd'
import {
  CheckOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  CloseOutlined
} from '@ant-design/icons'

import { InputWrapper } from '../../styled/common'

import { theme } from '../../layout/Theme'

export default (props) => {
  const {
    type,
    defaultValue,
    verified,
    maxLength,
    backgroundColor,
    borderBottom,
    handleChange
  } = props
  const [hiddenPassword, setHiddenPassword] = useState(true)

  return (
    <InputWrapper
      style={
        !defaultValue || defaultValue === '' ?
          {backgroundColor: theme.colors.white}
        :
          {}
      }
    >
      <Input
        type={type && hiddenPassword ? type : 'text'}
        defaultValue={defaultValue || ''}
        onChange={e => handleChange ? handleChange(e.target.value) : {}}
        size='large'
        maxLength={maxLength}
        style={{
          backgroundColor: backgroundColor || 'transparent',
          border: 'none',
          borderBottom: borderBottom || 'none',
          borderRadius: '0'
        }}
        suffix={
          <>
            {type === 'password' && hiddenPassword ?
              <EyeOutlined
                style={{
                  cursor: 'pointer'
                }}
                onClick={() => setHiddenPassword(false)}
              />
            : null}
            {type === 'password' && !hiddenPassword ?
              <EyeInvisibleOutlined
                style={{
                  cursor: 'pointer'
                }}
                onClick={() => setHiddenPassword(true)}
              />
            : null}
            {defaultValue && defaultValue !== '' && verified === true ?
              <CheckOutlined
                style={{
                  marginLeft: '10px',
                  color: '#3bb037'
                }}
              />
            : null}
            {defaultValue && defaultValue !== '' && verified === false ?
              <CloseOutlined
                style={{
                  marginLeft: '10px',
                  color: 'red'
                }}
              />
            : null}
          </>
        }
      />
    </InputWrapper>
  )
}
