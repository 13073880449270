import IBAN from 'iban'

export const isValidSIREN = (siren) => {
  let isValid
  if (!siren || siren.length !== 9 || isNaN(siren)) {
    isValid = false
  } else {
    // Donc le SIREN est un numérique à 9 chiffres
    let somme = 0
    let tmp

    for (let cpt = 0; cpt < siren.length; cpt++) {
      if (cpt % 2 === 1) {
        // Les positions paires : 2ème, 4ème, 6ème et 8ème chiffre
        tmp = siren.charAt(cpt) * 2 // On le multiplie par 2
        if (tmp > 9) tmp -= 9 // Si le résultat est supérieur à 9, on lui soustrait 9
      } else tmp = siren.charAt(cpt)
      somme += parseInt(tmp)
    }

    if (somme % 10 === 0) {
      isValid = true // Si la somme est un multiple de 10 alors le SIREN est valide
    } else {
      isValid = false
    }
  }

  return isValid
}

export const isValidPhoneNumber = (phone) => {
  var reg = new RegExp('^[0-9s]*$')
  if (phone && reg.test(phone) && phone.length === 10 && phone.substring(0, 1) === '0') {
    return true
  }
  return false
}

export const isValidNIC = (nic) => {
  var reg = new RegExp('^[0-9s]*$')
  if (nic && reg.test(nic) && nic.length === 5) {
    return true
  }
  return false
}

export const isValidPostalCode = (postalCode) => {
  var reg = new RegExp('^[0-9s]*$')
  if (postalCode && reg.test(postalCode) && postalCode.length === 5) {
    return true
  }
  return false
}

export const isValidEmail = (email) => {
  var reg = new RegExp('^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')
  if (email && reg.test(email)) {
    return true
  }
  return false
}

export const requiredFields = (state) => {
  const keys = Object.keys(state)
  let required = []

  if (!keys.includes('companyName')) required.push('Société')
  if (!keys.includes('companySIREN')) required.push('SIREN')
  if (!keys.includes('companyAddress')) required.push('Adresse de la société')
  if (!keys.includes('companyPostCode')) required.push('Code postal de la société')
  if (!keys.includes('companyTown')) required.push('Ville de la société')
  if (!keys.includes('customerCivility')) required.push('Civilité de l\'utilisateur')
  if (!keys.includes('customerLastname')) required.push('Nom de l\'utilisateur')
  if (!keys.includes('customerFirstname'))required.push('Prénom de l\'utilisateur')
  if (!keys.includes('customerFunctionId')) required.push('Fonction de l\'utilisateur')
  if (!keys.includes('customerEmail')) required.push('Email de l\'utilisateur')
  if (!keys.includes('customerPhone')) required.push('Téléphone de l\'utilisateur')
  if (!keys.includes('bankId')) required.push('Banque')
  if (!keys.includes('companyIBAN')) required.push('IBAN')
  if (!keys.includes('offerArticleId')) required.push('Offre')

  if (
    keys.includes('customerLegalCivility') ||
    keys.includes('customerLegalLastname') ||
    keys.includes('customerLegalFirstname') ||
    keys.includes('customerLegalFunctionId') ||
    keys.includes('customerLegalEmail') ||
    keys.includes('customerLegalPhone')
  ) {
    if (!keys.includes('customerLegalCivility')) required.push('Civilité du représentant légal')
    if (!keys.includes('customerLegalLastname')) required.push('Nom du représentant légal')
    if (!keys.includes('customerLegalFirstname')) required.push('Prénom du représentant légal')
    if (!keys.includes('customerLegalFunctionId')) required.push('Fonction du représentant légal')
    if (!keys.includes('customerLegalEmail')) required.push('Email du représentant légal')
    if (!keys.includes('customerLegalPhone')) required.push('Téléphone du représentant légal')
  }

  return required
}

export const invalidFields = (state) => {
  const keys = Object.keys(state)
  let invalid = []

  keys.forEach(key => {
    switch (key) {
      case 'companySIREN':
        if (!isValidSIREN(state[key])) invalid.push('SIREN')
        return

      case 'companyPostCode':
        if (!isValidPostalCode(state[key])) invalid.push('Code postal de la société')
        return

      case 'customerPhone':
        if (!isValidPhoneNumber(state[key])) invalid.push('Téléphone de l\'utilisateur')
        return

      case 'customerEmail':
        if (!isValidEmail(state[key])) invalid.push('Email de l\'utilisateur')
        return

      case 'companyIBAN':
        if (!IBAN.isValid(state[key])) invalid.push('IBAN')
        return

      case 'customerLegalEmail':
        if (!isValidEmail(state['customerLegalEmail']) || (state['customerEmail'] === state['customerLegalEmail'])) invalid.push('Email du représentant légal')
        return

      case 'customerLegalPhone':
        if (!isValidPhoneNumber(state['customerLegalPhone'])) invalid.push('Téléphone du représentant légal')
        return

      default:
        return
    }
  })

  return invalid
}

export const isValidForm = (state) => {
  const keys = Object.keys(state)
  let isValid = true

  if (
    !keys.includes('companyName') ||
    !keys.includes('companySIREN') ||
    !keys.includes('companyAddress') ||
    !keys.includes('companyPostCode') ||
    !keys.includes('companyTown') ||
    !keys.includes('customerCivility') ||
    !keys.includes('customerLastname') ||
    !keys.includes('customerFirstname') ||
    !keys.includes('customerFunctionId') ||
    !keys.includes('customerEmail') ||
    !keys.includes('customerPhone') ||
    !keys.includes('bankId') ||
    !keys.includes('companyIBAN') ||
    !keys.includes('offerArticleId')
  ) isValid = false

  keys.forEach(key => {
    switch (key) {
      case 'companyName':
      case 'companyAddress':
      case 'companyTown':
      case 'customerCivility':
      case 'customerLastname':
      case 'customerFirstname':
      case 'customerFunctionId':
      case 'bankId':
      case 'offerArticleId':
        if (!state[key] || state[key] === '') isValid = false
        return

      case 'companySIREN':
        if (!state[key] || state[key] === '' || !isValidSIREN(state[key])) isValid = false
        return

      case 'companyPostCode':
        if (!state[key] || state[key] === '' || !isValidPostalCode(state[key])) isValid = false
        return

      case 'customerPhone':
        if (!state[key] || state[key] === '' || !isValidPhoneNumber(state[key])) isValid = false
        return

      case 'customerEmail':
        if (!state[key] || state[key] === '' || !isValidEmail(state[key])) isValid = false
        return

      case 'companyIBAN':
        if (!state[key] || state[key] === '' || !IBAN.isValid(state[key])) isValid = false
        return

      case 'customerLegalCivility':
      case 'customerLegalLastname':
      case 'customerLegalFirstname':
      case 'customerLegalFunctionId':
      case 'customerLegalEmail':
      case 'customerLegalPhone':
        if (
          (state[key] && state[key] !== '')
          &&
          (
            !state['customerLegalCivility'] || state['customerLegalCivility'] === ''
            || !state['customerLegalLastname'] || state['customerLegalLastname'] === ''
            || !state['customerLegalFirstname'] || state['customerLegalFirstname'] === ''
            || !state['customerLegalFunctionId'] || state['customerLegalFunctionId'] === ''
            || !state['customerLegalEmail'] || state['customerLegalEmail'] === ''
            || !state['customerLegalPhone'] || state['customerLegalPhone'] === ''
          )
        ) isValid = false
        if (state['customerLegalPhone'] && state['customerLegalPhone'] !== '' && !isValidPhoneNumber(state['customerLegalPhone'])) isValid = false
        if (state['customerLegalEmail'] && state['customerLegalEmail'] !== '' && !isValidEmail(state['customerLegalEmail'])) isValid = false
        if (state['customerEmail'] === state['customerLegalEmail']) isValid = false
        return

      default:
        return
    }
  })

  return isValid
}
