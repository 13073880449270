import React from 'react'
import {
  Dropdown,
  Card
} from 'antd'
import { PhoneOutlined } from '@ant-design/icons'

export default () => {
  return (
    <Dropdown
      overlay={
        <>
          <Card
            title="LE SERVICE CLIENTS A VOTRE DISPOSITION"
            headStyle={{fontSize: '14px'}}
            style={{
              width: '400px',
              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
              borderRadius: '5px',
              margin: '10px',
              fontSize: '12px'
            }}
          >
            <p style={{marginBottom: '5px'}}>Du lundi au jeudi de <strong>8h30</strong> à <strong>17h30</strong></p>
            <p style={{marginBottom: '5px'}}>Le vendredi de <strong>8h30</strong> à <strong>17h00</strong></p>
            <p style={{marginBottom: '5px'}}>Téléphone: <strong>+33 1 41 37 51 12</strong></p>
            <p>Email: <strong>partenariats@altares.com</strong></p>            
          </Card>
        </>
      }
      placement='bottomRight'
    >
      <PhoneOutlined
        style={{
          width: '60px',
          backgroundColor: '#c6c6c6',
          fontSize: '30px',
          lineHeight: '60px',
          color: '#fff',
          cursor: 'pointer'
        }}
      />
    </Dropdown>
  )
}
