import { useState, useEffect } from 'react'

let hostname = window.location.hostname
const dot = hostname.indexOf('.')
if (dot !== -1) {
  hostname = hostname.substring(0, dot)
}

export default (fetchedConfig) => {
  const { env } = window
  const [status, setStatus] = useState(false)

  useEffect(() => {
    if (env) {
      setStatus(true)
    }

    if (!env && fetchedConfig.status === 'success') {
      const config = Object.assign(fetchedConfig.data[hostname] || fetchedConfig.data.default, fetchedConfig.data.envs.default)

      window.env = config
      setStatus(true)
    }
  }, [fetchedConfig, env])

  return status
}
