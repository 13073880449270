import React from 'react'
import {
  Affix,
  Row,
  Col,
  Menu
} from 'antd'

import NavigationMenu from './header/NavigationMenu'
import ContactCard from './header/ContactCard'
import SocialMenu from './header/SocialMenu'
import Stepper from '../modules/register/Stepper'

import { HeaderWrapper } from '../styled/common'

export default () => {
  const { env } = window

  return (
    <Affix>
      <HeaderWrapper>
        <Row>
          <Col span={18}>
            <Menu
              mode='horizontal'
              style={{
                borderBottom: 'none'
              }}
            >
              <Menu.Item
                key='accueil'
                style={{
                  borderBottomColor: 'transparent'
                }}
                icon={
                  <>
                    <img
                      src={`${process.env.PUBLIC_URL}/logo_altares.png`}
                      alt='logo'
                      style={{
                        maxWidth: '100%',
                        height: '40px',
                        margin: '10px 20px 10px 0',
                        paddingRight: '20px',
                        borderRight: '1px solid #c6c6c6'
                      }}
                    />
                    <img
                      src={`${process.env.PUBLIC_URL}${env.logoSrc}`}
                      alt='logo'
                      style={{
                        maxWidth: '100%',
                        height: env.logoHeight,
                        margin: '10px 0'
                      }}
                    />
                  </>
                }
              >
              </Menu.Item>
            </Menu>
          </Col>
          <Col span={6} style={{textAlign: 'right'}}>
            <ContactCard />
          </Col>
        </Row>
        <Row>
          <Col
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 18}}
          >
            <NavigationMenu />
          </Col>
          <Col
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 6}}
          >
            <SocialMenu />
          </Col>
        </Row>
        <Stepper />
      </HeaderWrapper>
    </Affix>
  )
}
