import React, { useEffect } from 'react'
import { BackTop } from 'antd';

import { Theme } from './Theme'
import Header from './Header'
import Footer from './Footer'

import useFetch from '../modules/common/useFetch'
import useConfig from '../modules/common/useConfig'

export default ({children}) => {
  const fetchedConfig = useFetch('/config.json', { method: 'GET' }, true)
  const config = useConfig(fetchedConfig)

  useEffect(() => {
    sessionStorage.removeItem('blob-url')
  }, [])

  return (
    <>
      {config ?
        <Theme>
          <Header />
          <main>{children}</main>
          <Footer />
          <BackTop />
        </Theme>
      : null}
    </>
  )
}
