import styled, { css } from 'styled-components'
import {
  Result,
  Collapse,
  List
} from 'antd'
import {
  CheckOutlined
} from '@ant-design/icons'

export const StepperWrapper = styled.div`
  padding: 30px;
  background-color: ${props => props.theme.colors.lightBlue};
  font-family: ${props => props.theme.fonts.medium};
  border-top: 1px solid ${props => props.theme.colors.lightGrey};

  @media only screen and (max-width: 450px) {
    padding: 20px 30px 0 30px;
  }

  .ant-steps-item-title {
    font-size: 13px;
  }

  .ant-steps-item-process,
  .ant-steps-item-finish {

    .ant-steps-icon {
      color: ${props => props.theme.colors.mediumBlue} !important;
    }
  }

  .ant-steps-item-finish {

    .ant-steps-item-title:after {
      background-color: ${props => props.theme.colors.mediumBlue} !important;
    }
  }

  .ant-steps-item-process,
  .ant-steps-item-wait {

    .ant-steps-item-title:after {
      background-color: ${props => props.theme.colors.lightGrey} !important;
    }
  }
`

export const AnchorMenuWrapper = styled.div`
  padding: 30px 0;
  font-family: ${props => props.theme.fonts.medium};
  color: ${props => props.theme.colors.grey};

  @media only screen and (max-width: ${props => props.theme.breakpoints.md - 1}px) {
    visibility: hidden;
    height: 0;
    padding: 0;
    overflow: hidden;
  }

  a {
    font-size: 12px;

    &:hover {
      color: ${props => props.theme.colors.mediumBlue};
    }
  }

  .uppercase > a {
    text-transform: uppercase;
  }

  .ant-anchor-link-title-active {
    color: ${props => props.theme.colors.mediumBlue};
  }

  .ant-anchor-ink:before {
    background-color: ${props => props.theme.colors.lightGrey};
  }

  .ant-anchor-ink-ball {
    border-color: ${props => props.theme.colors.mediumBlue};
  }
`

const title = css`
  margin: 30px 0;
  padding-bottom: 10px;
  font-family: ${props => props.theme.fonts.medium};
  color: ${props => props.theme.colors.grey};
  border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
`

export const FormSectionTitle = styled.h3`
  ${title};
  font-size: 16px;
  text-transform: uppercase;
`

export const FormSectionSubTitle = styled.h4`
  ${title};
  font-size: 14px;
`

export const Offer = styled.div`
  position: relative;
  padding: 10px;
  margin: 0 15px 15px 0;
  border: 2px solid ${props => props.theme.colors.darkBlue};
  border-radius: 5px;
  background-color: ${props => props.theme.colors.lightBlue};
  color: ${props => props.theme.colors.darkBlue};
  text-align: center;
  cursor: pointer;

  &.selected {
    background-color: ${props => props.theme.colors.darkBlue};
    color: ${props => props.theme.colors.white};
  }
`

export const OfferChecked = styled(CheckOutlined)`
  position: absolute;
  top: 5px;
  right: 5px;
  color: ${props => props.theme.colors.mediumBlue};
  font-size: 14px;
`

export const OfferName = styled.div`
  font-family: ${props => props.theme.fonts.medium};
  font-size: 14px;
`

export const OfferType = styled.div`
  font-family: ${props => props.theme.fonts.medium};
  font-size: 20px;
  text-transform: uppercase;
`

export const OfferLimit = styled.div`
  margin: 5px 0;
  font-size: 12px;
  height: 40px;
  line-height: 40px;

  strong {
    font-family: ${props => props.theme.fonts.bold};
    color: ${props => props.theme.colors.mediumBlue};
  }
`

export const OfferPrice = styled.div`
  font-family: ${props => props.theme.fonts.bold};
  font-size: 20px;
`

export const StepResult = styled(Result)`
  padding: 30px 10px;

  .ant-result-icon {
    margin-bottom: 10px;

    .anticon {
      font-size: 50px;
    }
  }

  .ant-result-content {
    padding: 20px;
  }

  .ant-steps-item-icon {
    background-color: ${props => props.theme.colors.mediumBlue};
    border-color: ${props => props.theme.colors.mediumBlue};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.theme.colors.mediumBlue};
    border-color: ${props => props.theme.colors.mediumBlue};
  }
`

export const CollapseContainer = styled(Collapse)`
  margin: 30px 0;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.lightGrey};
  border-radius: 5px;
  font-size: 12px;
  font-family: ${props => props.theme.fonts.medium};
`

export const CollapsePanel = styled(Collapse.Panel)`
  border-color: ${props => props.theme.colors.lightGrey} !important;

  &:last-child {
    border: none;
  }

  .ant-collapse-header {
    color: ${props => props.theme.colors.darkBlue} !important;
  }

  .ant-collapse-content-box {
    padding: 0;
  }
`

export const DataList = styled(List)`
  font-size: 11px;

  .ant-list-header {
    padding: 8px 16px;
    background-color: ${props => props.theme.colors.lightBlue};
    border-top: 1px solid ${props => props.theme.colors.lightGrey};
    border-bottom: none;
    font-family: ${props => props.theme.fonts.bold};
  }
`

export const ListItem = styled(List.Item)`
  &:first-child {
    border-top: 1px solid ${props => props.theme.colors.lightGrey};
  }

  &:hover {
    background-color: ${props => props.theme.colors.lightBlue};
  }

  .ant-row {

    .ant-col {

      &:last-child {
        font-family: ${props => props.theme.fonts.bold};
      }
    }
  }
`

export const SubmitTooltipContent = styled.div`
  font-size: 12px;

  ul {
    padding-left: 20px;
  }
`
