import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  Row,
  Col,
  Steps
} from 'antd'
import { UserOutlined, SolutionOutlined, CheckOutlined } from '@ant-design/icons'

import { StepperWrapper } from '../../styled/register'

const Stepper = (props) => {
  const { pathname } = props.location
  const visible =
    pathname === '/inscription' ||
    pathname === '/contrat' ||
    pathname === '/bienvenue'
  const statuses = {
    '/inscription': ['process', 'wait', 'wait'],
    '/contrat': ['finish', 'process', 'wait'],
    '/bienvenue': ['finish', 'finish', 'process']
  }

  return (
    <>
      {visible ?
        <StepperWrapper>
          <Row>
            <Col
              xs={{span: 24}}
              sm={{span: 24}}
              md={{
                span: 18,
                offset: 3
              }}
            >
              <Steps size='small'>
                <Steps.Step status={statuses[pathname][0]} title='Mes informations' icon={<UserOutlined />} />
                <Steps.Step status={statuses[pathname][1]} title='Mon contrat' icon={<SolutionOutlined />} />
                <Steps.Step status={statuses[pathname][2]} title='Inscription' icon={<CheckOutlined />} />
              </Steps>
            </Col>
          </Row>
        </StepperWrapper>
      : null}
    </>
  )
}

export default withRouter(Stepper)
