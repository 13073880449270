import React from 'react'
import {
  Menu,
  Tooltip
} from 'antd'
import {
  LinkedinFilled,
  TwitterOutlined,
  YoutubeFilled,
  FacebookFilled
} from '@ant-design/icons'

import { theme } from '../Theme'

const menuStyle = {
  borderBottom: 'none',
  textAlign: 'right'
}

const itemStyle = {
  borderBottomColor: 'transparent',
  fontSize: '20px'
}

const iconStyle = {
  fontSize: '20px',
  color: theme.colors.lightGrey
}

export default () => {
  const handleClick = (e) => {
    window.open(e.key);
  }

  return (
    <Menu
      mode='horizontal'
      selectable={false}
      style={menuStyle}
      onClick={e => handleClick(e)}
    >
      <Menu.Item
        key='https://fr.linkedin.com/company/altares-dun-and-bradstreet'
        style={itemStyle}
        icon={
          <Tooltip
            placement='bottom'
            title='Linkedin'
            color={theme.colors.lightGrey}
          >
            <LinkedinFilled style={iconStyle} />
          </Tooltip>
        }
      ></Menu.Item>
      <Menu.Item
        key='https://twitter.com/AltaresDB'
        style={itemStyle}
        icon={
          <Tooltip
            placement='bottom'
            title='Twitter'
            color={theme.colors.lightGrey}
          >
            <TwitterOutlined style={iconStyle} />
          </Tooltip>
        }
      ></Menu.Item>
      <Menu.Item
        key='https://www.youtube.com/channel/UCJYfbmijQl7A6xAbC38he6Q'
        style={itemStyle}
        icon={
          <Tooltip
            placement='bottom'
            title='Youtube'
            color={theme.colors.lightGrey}
          >
            <YoutubeFilled style={iconStyle} />
          </Tooltip>
        }
      ></Menu.Item>
      <Menu.Item
        key='https://www.facebook.com/AltaresDB/'
        style={itemStyle}
        icon={
          <Tooltip
            placement='bottomLeft'
            arrowPointAtCenter={true}
            title='Facebook'
            color={theme.colors.lightGrey}
          >
            <FacebookFilled style={iconStyle} />
          </Tooltip>
        }
      ></Menu.Item>
    </Menu>
  )
}
