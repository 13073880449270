import { useState, useEffect } from 'react'

export default (path, options, perform) => {
  const [status, setStatus] = useState('')
  const [code, setCode] = useState(0)
  const [data, setData] = useState(null)

  useEffect(() => {
    const callFetch = async () => {
      try {
        let json: any = null
        const response = await fetch(path, options)
        setCode(response.status)

        if (response.ok && response.status !== 204) json = await response.json()
        if (response.status === 204 || !response.ok) {
          json = { message: response.statusText }
        }

        setData(json)
      } catch (err) {
        console.log(err)
      }
    }

    if (data && !perform) {
      setStatus('')
      setCode(0)
      setData(null)
    }

    if (!perform) return

    if (data && code !== 0 && status === 'pending') {
      setStatus(code < 400 ? 'success' : 'error')
    }

    if (!data && status === '') {
      setStatus('pending')
      callFetch()
    }
  }, [path, options, perform, status, code, data])

  return {status, code, data}
}
