import { useState, useEffect } from 'react'

const b64toBlob = (base64) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new Blob([bytes], { type: 'application/pdf' });
};

export default (b64Data, perform) => {
  const [blobUrl, setBlobUrl] = useState('')

  useEffect(() => {
    if (blobUrl !== '' && !perform) {
      setBlobUrl('')
    }

    if (!perform || b64Data === '') return

    if (b64Data !== '' && blobUrl === '') {
      const blob = b64toBlob(b64Data, '	application/pdf')
      setBlobUrl(URL.createObjectURL(blob))
    }
  }, [b64Data, perform, blobUrl, setBlobUrl])

  return blobUrl
}
