import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  Menu,
  Button
} from 'antd'
import { UserAddOutlined } from '@ant-design/icons'

import { theme } from '../Theme'

const menuStyle = {
  borderBottom: 'none',
  fontSize: '12px'
}

const itemStyle = {
  fontFamily: theme.fonts.book,
  color: theme.colors.grey,
  textTransform: 'uppercase'
}

const buttonStyle = {
  backgroundColor: theme.colors.mediumBlue,
  color: theme.colors.white,
  borderColor: theme.colors.mediumBlue
}

const NavigationMenu = (props) => {
  const { location, history } = props

  const handleClick = (e) => {
    history.push(e.key)
  }

  return (
    <Menu
      selectedKeys={[location.pathname === '/inscription' || location.pathname === '/contrat' || location.pathname === '/bienvenue' ? '/inscription' : location.pathname]}
      mode='horizontal'
      onClick={e => handleClick(e)}
      style={menuStyle}
      className='header-navigation'
    >
      <Menu.Item
        key='/'
        style={itemStyle}
      >Accueil</Menu.Item>
      <Menu.Item
        key='/qui-sommes-nous'
        style={itemStyle}
      >Qui sommes nous</Menu.Item>
      <Menu.Item
        key='/notre-offre'
        style={itemStyle}
      >Notre offre</Menu.Item>
      <Menu.Item
        key='/inscription'
        style={itemStyle}
      >
        <Button
          style={buttonStyle}
          icon={<UserAddOutlined style={{fontSize: '16px'}} />}
        >Inscription</Button>
      </Menu.Item>
    </Menu>
  )
}

export default withRouter(NavigationMenu)
