import React, { useEffect } from 'react'

import WelcomeStep from '../modules/register/WelcomeStep'

export default () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <WelcomeStep />
  )
}
