import React, { useEffect, useCallback } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import {
  Row,
  Col,
  Steps,
  Divider
} from 'antd'
import {
  DownloadOutlined
} from '@ant-design/icons'

import {
  StepResult
} from '../../styled/register'
import {
  SeeButton,
  LinkButton,
  DividerWrapper
} from '../../styled/common'

import { theme } from '../../layout/Theme'

const WelcomeStep = (props) => {
  const { history } = props
  const blobUrl = sessionStorage.getItem('blob-url')
  const pdf = blobUrl || ''

  const exportFromBlob = useCallback(() => {
    let a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = pdf
    a.download = 'contrat-intuiz'
    a.click()
    a.remove()
  }, [pdf])

  useEffect(() => {
    if (blobUrl) {
      exportFromBlob()
    }
  }, [blobUrl, exportFromBlob])

  useEffect(() => {
    sessionStorage.removeItem('offer')
    sessionStorage.removeItem('function')
    sessionStorage.removeItem('legalfunction')
    sessionStorage.removeItem('bank')
    sessionStorage.removeItem('register-form-data')
  }, [])

  return (
    <>
      {!blobUrl ?
        <Redirect to='/' />
      : null}
      <Row>
        <Col
          xs={{span: 24}}
          sm={{span: 24}}
          md={{
            span: 18,
            offset: 3
          }}
        >
          <StepResult
            status='success'
            title='Félicitation, vous êtes maintenant inscrit sur intuiz+ !'
            subTitle={'Encore quelques étapes pour valider mon contrat :'}
          >
            <Row>
              <Col
                xs={{span: 24}}
                sm={{span: 24}}
                md={{span: 12}}
                style={{
                  textAlign: 'center',
                  padding: '0 30px 30px 0'
                }}
              >
                <Steps direction='vertical'>
                  <Steps.Step status='process' title='Je télécharge mon contrat' description='Le téléchargement est automatique suite à la confirmation de votre inscription. Vous pouvez si besoin télécharger de nouveau votre contrat.' />
                  <Steps.Step status='process' title='Je complète mon contrat' />
                  <Steps.Step status='process' title='Je transmets mon contrat par email ou par courrier' description='Les adresses email et postale sont précisés ci-contre' />
                </Steps>
              </Col>
              <Col
                xs={{span: 24}}
                sm={{span: 24}}
                md={{span: 12}}
                style={{
                  textAlign: 'center',
                  border: `1px solid ${theme.colors.lightGrey}`,
                  borderRadius: '5px',
                  padding: '24px 0'
                }}
              >
                <SeeButton
                  type='primary'
                  shape='round'
                  icon={<DownloadOutlined />}
                  style={{
                    backgroundColor: theme.colors.darkBlue,
                    borderColor: theme.colors.darkBlue
                  }}
                  onClick={() => exportFromBlob()}
                >Télécharger mon contrat</SeeButton>
                <DividerWrapper>
                  <Divider></Divider>
                </DividerWrapper>
                <span style={{
                  fontFamily: theme.fonts.bold
                }}>Email : </span>
                <span>contrats@altares.com</span>
                <DividerWrapper>
                  <Divider></Divider>
                </DividerWrapper>
                <div style={{
                  fontFamily: theme.fonts.bold
                }}>ALTARES D&B</div>
                <div>Campus Equilibre - Bâtiment Le Yard</div>
                <div>52-58 avenue Jean Jaurès</div>
                <div>92700 Colombes</div>
              </Col>
            </Row>
          </StepResult>
        </Col>
      </Row>
      <div
        style={{
          textAlign: 'center'
        }}
      >
        <LinkButton
          size='large'
          type='primary'
          style={{
            margin: '10px'
          }}
          onClick={() => history.push('/')}
        >ACCUEIL</LinkButton>
        <LinkButton
          size='large'
          type='primary'
          style={{
            margin: '10px'
          }}
          onClick={() => history.push('/qui-sommes-nous')}
        >QUI SOMMES NOUS</LinkButton>
        <LinkButton
          size='large'
          type='primary'
          style={{
            margin: '10px'
          }}
          onClick={() => history.push('/notre-offre')}
        >NOTRE OFFRE</LinkButton>
      </div>
    </>
  )
}

export default withRouter(WelcomeStep)
