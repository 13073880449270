import React, { useState } from 'react'
import {
  Row,
  Col,
  Menu,
  Modal
} from 'antd'

import View from '../modules/common/View'
import SocialMenu from './header/SocialMenu'

import { FooterWrapper } from '../styled/common'

export default () => {
  const [legalNoticeModal, setLegalNoticeModal] = useState(false)

  return (
    <FooterWrapper>
      <Row>
        <Col>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/logo_altares_white.png`}
              alt='logo'
              style={{
                height: '70px',
                margin: '20px',
                paddingRight: '20px',
                borderRight: '1px solid #fff'
              }}
            />
          </div>
        </Col>
        <Col>
          <div
            style={{
              margin: '20px'
            }}
          >
            <div><strong>ALTARES D&B</strong></div>
            <div>Campus Equilibre - Bâtiment Le Yard</div>
            <div>52-58 avenue Jean Jaurès</div>
            <div>92700 Colombes</div>
          </div>
        </Col>
      </Row>
      <Row
        style={{
          backgroundColor: '#282a2b'
        }}
      >
        <Col
          xs={{span: 24}}
          sm={{span: 24}}
          md={{span: 18}}
        >
          <Menu
            mode='horizontal'
            selectable={false}
            style={{
              borderBottom: 'none',
              color: 'white'
            }}
          >
            <Menu.Item
              style={{
                color: '#c6c6c6',
                borderBottomColor: 'transparent'
              }}
              onClick={() => setLegalNoticeModal(true)}
            >Mentions légales</Menu.Item>
          </Menu>
          <Modal
            title='Mentions légales'
            visible={legalNoticeModal}
            width={800}
            footer={null}
            onOk={() => setLegalNoticeModal(false)}
            onCancel={() => setLegalNoticeModal(false)}
          >
            <View name='legal-notice' />
          </Modal>
        </Col>
        <Col
          xs={{span: 24}}
          sm={{span: 24}}
          md={{span: 6}}
        >
          <SocialMenu />
        </Col>
      </Row>
    </FooterWrapper>
  )
}
