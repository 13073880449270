import React, { useState, useEffect } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  message,
  Row,
  Col,
  Typography,
  Divider,
  Checkbox,
  Modal,
  Space
} from 'antd'
import {
  SolutionOutlined,
  EyeOutlined,
  RightOutlined,
  LeftOutlined,
} from '@ant-design/icons'

import View from '../common/View'

import useFetch from '../common/useFetch'
import usePdf from '../common/usePdf'

import {
  DividerWrapper,
  SeeButton,
  BackButton,
  SubmitButton
} from '../../styled/common'
import {
  StepResult,
  CollapseContainer,
  CollapsePanel,
  DataList,
  ListItem
} from '../../styled/register'

import { theme } from '../../layout/Theme'

const recaptchaRef = React.createRef();

const ContractStep = ({ history }) => {
  const registerFormData = sessionStorage.getItem('register-form-data')
  const state = registerFormData ? JSON.parse(registerFormData) : {}
  const customerFunction = sessionStorage.getItem('function')
  const customerLegalFunction = sessionStorage.getItem('legalFunction')
  const bank = sessionStorage.getItem('bank')
  const offer = sessionStorage.getItem('offer')
  const keys = Object.keys(state)
  const params = keys.map(key => `${key}=${encodeURIComponent(state[key])}`).join('&')
  const captcha = useFetch(`/api/captcha-key-site`, { method: 'GET' }, true)
  const captchaKeySite = captcha.data && captcha.data.captchaKeySite && captcha.data.captchaKeySite !== '' ? captcha.data.captchaKeySite : null
  const [gRecaptchaResponse, setGRecaptchaResponse] = useState('')
  const base64Pdf = useFetch(`/api/generatePDFContract?${params}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }, true)
  const pdf = usePdf(base64Pdf.status === 'success' ? base64Pdf.data.base64PDF : '', true)
  const [performUser , setPerformUser] = useState(false)
  const user = useFetch(`/api/createCustomerRegistration?${params}&g-recaptcha-response=${gRecaptchaResponse}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }, performUser)
  const [cgsModal, setCgsModal] = useState(false)
  const [rgpdModal, setRgpdModal] = useState(false)
  const openFromBlob = () => {
    window.open(pdf, '_blank')
  }
  const [cgs, setCgs] = useState(false)
  const [rgpd, setRgpd] = useState(false)

  const submit = (e) => {
    e.preventDefault()
    message.destroy()
    setPerformUser(true)
  }

  useEffect(() => {
    if (performUser && user.status === 'success' && user.data.statusMessage === 'OK') {
      sessionStorage.setItem('blob-url', pdf)
      history.push('/bienvenue')
    }
    if (performUser && user.status === 'success' && user.data.statusMessage !== 'OK') {
      message.error(user.data.statusCode === 300 ?
        user.data.statusMessage.split('>')[1]
        :
        user.data.statusMessage, 2.5
      )
      setPerformUser(false)
      if (captchaKeySite) {
        recaptchaRef.current.reset()
        setGRecaptchaResponse('')
      }
    }
    if (performUser && user.status === 'error' && user.code === 429) {
      message.error('Service d\'inscription temporairement indisponible, merci de réessayer ultérieurement', 2.5)
      setPerformUser(false)
      if (captchaKeySite) {
        recaptchaRef.current.reset()
        setGRecaptchaResponse('')
      }
    }
    if (performUser && user.status === 'error' && user.code !== 429) {
      message.error('Erreur interne, merci de contacter le service clients', 2.5)
      setPerformUser(false)
      if (captchaKeySite) {
        recaptchaRef.current.reset()
        setGRecaptchaResponse('')
      }
    }
  }, [performUser, user, history, pdf, captchaKeySite])

  return (
    <>
      {base64Pdf.status === 'error' || (base64Pdf.status === 'success' &&  base64Pdf.data.statusMessage !== 'OK') ?
        <Redirect to='/inscription' />
      : null}
      <Row>
        <Col
          xs={{span: 24}}
          sm={{span: 24}}
          md={{
            span: 18,
            offset: 3
          }}
        >
          <StepResult
            style={{
              color: theme.colors.lightBlue
            }}
            icon={
              <SolutionOutlined
                style={{
                  color: theme.colors.mediumBlue
                }}
              />
            }
            title='Mon contrat'
            subTitle='Merci de valider les termes de votre contrat afin de finaliser votre inscription'
          >
            <div
              style={{
                textAlign: 'center'
              }}
            >
              <SeeButton
                type='primary'
                shape='round'
                icon={<EyeOutlined />}
                onClick={() => openFromBlob()}
              >Visualiser mon contrat</SeeButton>
            </div>
            <CollapseContainer
              bordered={false}
            >
              <CollapsePanel
                header='Mes informations'
                key='1'
                extra={
                  <Space>
                    <span>Voir le détail</span>
                    <EyeOutlined />
                  </Space>
                }
              >
                <DataList
                  size='small'
                  header='COORDONNÉES PROFESSIONNELLES'
                  dataSource={[
                    {label: 'Société', value: state.companyName},
                    {label: 'SIREN', value: state.companySIREN},
                    {label: 'NIC', value: state.companyNIC},
                    {label: 'Adresse', value: state.companyAddress},
                    {label: 'Code postal', value: state.companyPostCode},
                    {label: 'Ville', value: state.companyTown}
                  ]}
                  renderItem={item => (
                    <ListItem>
                      <Row style={{width: '100%'}}>
                        <Col span={12}>{item.label}</Col>
                        <Col
                          span={12}
                          style={{textAlign: 'right'}}
                        >{item.value}</Col>
                      </Row>
                    </ListItem>
                  )}
                />
                <DataList
                  size='small'
                  header='COORDONNÉES PERSONNELLES - Utilisateur du compte'
                  dataSource={[
                    {label: 'Civilité', value: state.customerCivility},
                    {label: 'Nom', value: state.customerLastname},
                    {label: 'Prénom', value: state.customerFirstname},
                    {label: 'Fonction', value: customerFunction},
                    {label: 'Email', value: state.customerEmail},
                    {label: 'Téléphone', value: state.customerPhone}
                  ]}
                  renderItem={item => (
                    <ListItem>
                      <Row style={{width: '100%'}}>
                        <Col span={12}>{item.label}</Col>
                        <Col
                          span={12}
                          style={{textAlign: 'right'}}
                        >{item.value}</Col>
                      </Row>
                    </ListItem>
                  )}
                />
                {state.customerLegalCivility ||
                state.customerLegalCivility ||
                state.customerLegalLastname ||
                state.customerLegalFirstname ||
                customerLegalFunction ||
                state.customerLegalEmail ||
                state.customerLegalPhone ?
                  <DataList
                    size='small'
                    header='COORDONNÉES PERSONNELLES - Représentant légal'
                    dataSource={[
                      {label: 'Civilité', value: state.customerLegalCivility},
                      {label: 'Nom', value: state.customerLegalLastname},
                      {label: 'Prénom', value: state.customerLegalFirstname},
                      {label: 'Fonction', value: customerLegalFunction},
                      {label: 'Email', value: state.customerLegalEmail},
                      {label: 'Téléphone', value: state.customerLegalPhone}
                    ]}
                    renderItem={item => (
                      <ListItem>
                        <Row style={{width: '100%'}}>
                          <Col span={12}>{item.label}</Col>
                          <Col
                            span={12}
                            style={{textAlign: 'right'}}
                          >{item.value}</Col>
                        </Row>
                      </ListItem>
                    )}
                  />
                : null}
                <DataList
                  size='small'
                  header='COORDONNÉES BANCAIRES'
                  dataSource={[
                    {label: 'Banque', value: bank},
                    {label: 'IBAN', value: state.companyIBAN},
                    {label: 'BIC', value: state.companyBIC}
                  ]}
                  renderItem={item => (
                    <ListItem>
                      <Row style={{width: '100%'}}>
                        <Col span={12}>{item.label}</Col>
                        <Col
                          span={12}
                          style={{textAlign: 'right'}}
                        >{item.value}</Col>
                      </Row>
                    </ListItem>
                  )}
                />
                <DataList
                  size='small'
                  header={'CONFIGURATION DE L\'OFFRE INTUIZ ZEN'}
                  dataSource={[
                    {label: 'Offre', value: offer}
                  ]}
                  renderItem={item => (
                    <ListItem>
                      <Row style={{width: '100%'}}>
                        <Col span={12}>{item.label}</Col>
                        <Col
                          span={12}
                          style={{textAlign: 'right'}}
                        >{item.value}</Col>
                      </Row>
                    </ListItem>
                  )}
                />
              </CollapsePanel>
              <CollapsePanel
                header='Tarifs'
                key='2'
                extra={
                  <Space>
                    <span>Voir le détail</span>
                    <EyeOutlined />
                  </Space>
                }
              >
                <DataList
                  size='small'
                  dataSource={base64Pdf.status === 'success' && base64Pdf.data.statusMessage === 'OK' ?
                    base64Pdf.data.tarifs
                      .filter(item => item.price !== 0)
                      .filter(item => !item.description.includes('Widget'))
                      .filter(item => !item.description.includes('Frais_de_gestion'))
                      .filter(item => !item.description.includes('Performance___info_financiere_5'))
                      .sort((a, b) => {
                        const typeA = a.description.split('_')[0]
                        const typeB = b.description.split('_')[0]

                        if(typeA === typeB) {
                          return (a.price < b.price) ? -1 : (a.price > b.price) ? 1 : 0
                        } else {
                          return typeA < typeB ? -1 : 1
                        }
                      })
                  : null}
                  renderItem={item => (
                    <ListItem>
                      <Row style={{width: '100%'}}>
                        <Col span={18}>{item.description.replace(new RegExp('_', 'g'), ' ')}</Col>
                        <Col
                          span={6}
                          style={{textAlign: 'right'}}
                        >{`${item.price} € H.T.`}</Col>
                      </Row>
                    </ListItem>
                  )}
                />
              </CollapsePanel>
            </CollapseContainer>
            <DividerWrapper>
              <Divider orientation='center'>Validation</Divider>
            </DividerWrapper>
            <Typography.Paragraph>
              <Checkbox style={{marginRight: '10px'}} onChange={e => setCgs(e.target.checked)}></Checkbox>
              <span>J'accepte les </span>
              <span onClick={() => setCgsModal(true)} style={{
                color: theme.colors.mediumBlue,
                cursor: 'pointer',
                textDecoration: 'underline'
              }}>conditions générales de services</span>
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Checkbox style={{marginRight: '10px'}} onChange={e => setRgpd(e.target.checked)}></Checkbox>
              <span>J'accepte le traitement de mes </span>
              <span onClick={() => setRgpdModal(true)} style={{
                color: theme.colors.mediumBlue,
                cursor: 'pointer',
                textDecoration: 'underline'
              }}>données personnelles</span>
              <span> par Altares D&B</span>
            </Typography.Paragraph>
            <Modal
              title='Conditions générales de services'
              visible={cgsModal}
              width={800}
              footer={null}
              onOk={() => setCgsModal(false)}
              onCancel={() => setCgsModal(false)}
            >
              <View name='cgs' />
            </Modal>
            <Modal
              title={'Conditions d\'utilisation des données personnelles'}
              visible={rgpdModal}
              width={800}
              footer={null}
              onOk={() => setRgpdModal(false)}
              onCancel={() => setRgpdModal(false)}
            >
              <View name='rgpd' />
            </Modal>
            <DividerWrapper>
              <Divider></Divider>
            </DividerWrapper>
            <Row>
              <Col
                xs={{span: 24}}
                sm={{span: 24}}
                md={{span: 12}}
              >
                <BackButton
                  size='large'
                  style={{
                    marginBottom: '20px'
                  }}
                  onClick={() => history.push('/inscription')}
                ><LeftOutlined />Retour</BackButton>
              </Col>
              <Col
                style={{
                  textAlign: 'right'
                }}
                xs={{span: 24}}
                sm={{span: 24}}
                md={{span: 12}}
              >
                {captchaKeySite ?
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    style={{
                      display: 'inline-block',
                      marginBottom: '20px'
                    }}
                    sitekey={captchaKeySite}
                    onChange={(value) => setGRecaptchaResponse(value || '')}
                  />
                : null}
                <SubmitButton
                  size='large'
                  type='primary'
                  disabled={cgs && rgpd && ((captchaKeySite && gRecaptchaResponse !== '') || (!captchaKeySite && gRecaptchaResponse === '')) ? '' : 'disabled'}
                  onClick={(e) => submit(e)}
                  loading={performUser && user.status === 'pending' ? true : false}
                >
                  Finaliser mon inscription<RightOutlined />
                </SubmitButton>
              </Col>
            </Row>
          </StepResult>
        </Col>
      </Row>
    </>
  )
}

export default withRouter(ContractStep)
