import React from 'react';
import { ThemeProvider } from 'styled-components';

export const theme = {
  colors: {
    white: '#fff',
    black: '#000',
    lightGrey: '#c6c6c6',
    mediumGrey: '#8c8989',
    grey: '#666666',
    darkGrey: '#282a2b',
    lightBlue: '#eef3f3',
    mediumBlue: '#25cbd3',
    darkBlue: '#005477'
  },
  fonts: {
    light: 'ITCAvantGardeW04-XLt',
    book: 'AvantGardeGothicITCW02Bk',
    medium: 'AvantGardeGothicITCW01Md',
    bold: 'AvantGardeGothicITCW02Bd'
  },
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600
  }
};

export const Theme = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  )
}
