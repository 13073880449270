import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'

import Layout from './layout/Layout'

import Home from './views/Home'
import About from './views/About'
import Product from './views/Product'
import LegalNotice from './views/LegalNotice'
import Register from './views/Register'
import Contract from './views/Contract'
import Welcome from './views/Welcome'

export default () =>
  <Router>
    <Layout>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/qui-sommes-nous" component={About} />
        <Route path="/notre-offre" component={Product} />
        <Route path="/mentions-legales" component={LegalNotice} />
        <Route path="/inscription" component={Register} />
        <Route path="/contrat" component={Contract} />
        <Route path="/bienvenue" component={Welcome} />
      </Switch>
    </Layout>
  </Router>
