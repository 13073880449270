import styled, { css } from 'styled-components'
import {
  Button
} from 'antd'

export const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
`

export const FooterWrapper = styled.footer`
  margin-top: 200px;
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.fonts.book};
  font-size: 12px;

  strong {
    font-family: ${props => props.theme.fonts.medium};
  }

  .ant-menu {
    background-color: transparent;
  }
`

export const Label = styled.label`
  color: ${props => props.theme.colors.mediumGrey};
  font-family: ${props => props.theme.fonts.book};
  font-size: 12px;
`

export const InputWrapper = styled.div`
  margin: 0 15px 10px 0;
  background-color: ${props => props.theme.colors.lightBlue};
  border: 1px solid ${props => props.theme.colors.lightGrey};
  border-radius: 2px;

  .ant-input-affix-wrapper {
    border-radius: 2px !important;
  }

  .ant-input {
    background-color: transparent;
  }
`

export const SelectWrapper = styled.div`
  margin: 0 15px 10px 0;

  .selected {

    .ant-select-selector {
      background-color: ${props => props.theme.colors.lightBlue} !important;
    }
  }

  .ant-select {
    width: 100%;
  }

  .ant-select-selector {
    height: 40px !important;
    border-radius: 2px !important;
    background-color: ${props => props.theme.colors.white} !important;
    border: 1px solid ${props => props.theme.colors.lightGrey} !important;
  }

  .ant-select-selection-item {
    line-height: 38px !important;
    font-family: ${props => props.theme.fonts.medium};
  }
`

export const DividerWrapper = styled.div`
  .ant-divider,
  .ant-divider:before,
  .ant-divider:after {
    border-color: ${props => props.theme.colors.lightGrey};
  }

  .ant-divider-inner-text {
    color: ${props => props.theme.colors.grey};
    font-family: ${props => props.theme.fonts.medium};
    font-size: 16px;
    text-transform: uppercase;
  }
`

const disabled = css`
  &:disabled {
    background-color: ${props => props.theme.colors.lightGrey};
    border-color: ${props => props.theme.colors.lightGrey};
  }
`

export const SeeButton = styled(Button)`
  background-color: ${props => props.theme.colors.darkBlue};
  border-color: ${props => props.theme.colors.darkBlue};

  &:hover {
    background-color: ${props => props.theme.colors.darkBlue};
    border-color: ${props => props.theme.colors.darkBlue};
    opacity: .8;
  }
`

export const BackButton = styled(Button)`
  color: ${props => props.theme.colors.mediumBlue};
  border-color: ${props => props.theme.colors.mediumBlue};
  text-transform: uppercase;
  font-size: 14px;

  &:hover {
    color: ${props => props.theme.colors.mediumBlue};
    border-color: ${props => props.theme.colors.mediumBlue};
    opacity: .8;
  }
`

export const SubmitButton = styled(Button)`
  background-color: ${props => props.theme.colors.mediumBlue};
  border-color: ${props => props.theme.colors.mediumBlue};
  text-transform: uppercase;
  font-size: 14px;
  ${disabled}

  &:hover {
    background-color: ${props => props.theme.colors.mediumBlue};
    border-color: ${props => props.theme.colors.mediumBlue};
    opacity: .8;
  }
`

export const LinkButton = styled(Button)`
  width: 200px;
  background-color: ${props => props.theme.colors.white};
  border-color: ${props => props.theme.colors.darkBlue};
  color: ${props => props.theme.colors.darkBlue};
  text-transform: uppercase;
  font-size: 14px;
  ${disabled}

  &:hover {
    background-color: ${props => props.theme.colors.darkBlue};
    border-color: ${props => props.theme.colors.darkBlue};
    color: ${props => props.theme.colors.white};
  }
`
