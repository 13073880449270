import React, { useState, useEffect, useCallback } from 'react'
import { Empty } from 'antd';

const getView = async (path) => {
  const response = await fetch(path)
  const data = await response.text()
  return data
}

export default (props) => {
  const { name, scrolltop } = props
  const { env } = window
  const [html, setHtml] = useState({__html: ''})

  const view = useCallback(() => {
    if (scrolltop) window.scrollTo(0, 0)

    getView(env.views[name]).then(html => {
      if (html) {
        const formatedHtml =
          html
            .replace(new RegExp('%logoSrc%', 'g'), env.logoSrc)
            .replace(new RegExp('%bankName%', 'g'), env.bankName)

        setHtml({__html: formatedHtml})
      } else {
        setHtml(null)
      }
    })
  }, [env, name, scrolltop])

  useEffect(() => {
    if (env) {
      view()
    }
  }, [env, view])

  return html.__html !== '' ?
    <section
      dangerouslySetInnerHTML={html}
    ></section>
    :
    <Empty
      style={{padding: '30px'}}
      description='Contenu temporairement indisponible'
    />
}
