import React, { useState } from 'react'
import { Anchor } from 'antd'

import { AnchorMenuWrapper } from '../../styled/register'

export default () => {
  const header = document.getElementsByTagName('header')[0]
  const offsetTop = header ? header.offsetHeight + 30 : 0
  const [display, setDisplay] = useState('block')

  return (
    <AnchorMenuWrapper
      style={{
        display: display
      }}
    >
      <Anchor
        offsetTop={offsetTop}
        onChange={affixed => {
          if (display === 'block' && affixed === '#next') setDisplay('none')
          if (display === 'none' && affixed !== '#next') setDisplay('block')
        }}
      >
        <Anchor.Link
          href='#company-section'
          title='Coordonnées professionnelles'
          className='uppercase'
        />
        <Anchor.Link
          href='#user-section'
          title='Coordonnées personnelles'
          className='uppercase'
        >
          <Anchor.Link
            href='#account-user-sub-section'
            title='Utilisateur du compte'
          />
          <Anchor.Link
            href='#legal-owner-sub-section'
            title='Représentant légal'
          />
        </Anchor.Link>
        <Anchor.Link
          href='#bank-section'
          title='Coordonnées bancaires'
          className='uppercase'
        />
        <Anchor.Link
          href='#offer-section'
          title="Configuration de l'offre intuiz zen"
          className='uppercase'
        />
        <Anchor.Link
          href='#next'
        />
      </Anchor>
    </AnchorMenuWrapper>
  )
}
