import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import {
  message,
  Row,
  Col,
  Select,
  Divider,
  Tooltip
} from 'antd'
import {
  RightOutlined,
  InfoCircleFilled
} from '@ant-design/icons'
import IBAN from 'iban'
import {
  isValidSIREN,
  isValidPhoneNumber,
  isValidNIC,
  isValidPostalCode,
  isValidEmail,
  requiredFields,
  invalidFields
} from './formats'

import VerifiedInput from '../common/VerifiedInput'
import AnchorMenu from '../register/AnchorMenu'

import useFetch from '../common/useFetch'

import {
  SelectWrapper,
  Label,
  DividerWrapper,
  SubmitButton
} from '../../styled/common'
import {
  FormSectionTitle,
  FormSectionSubTitle,
  Offer,
  OfferChecked,
  OfferName,
  OfferType,
  OfferLimit,
  OfferPrice,
  SubmitTooltipContent
} from '../../styled/register'

const RegisterForm = (props) => {
  const { env } = window
  const { history } = props
  const registerFormData = sessionStorage.getItem('register-form-data')
  const [state, setState] = useState(registerFormData ? JSON.parse(registerFormData) : {})
  const required = requiredFields(state)
  const invalid = invalidFields(state)
  const civilities = useFetch('/api/civility-list', { method: 'GET' }, true)
  const customerFunctions = useFetch('/api/function-list', { method: 'GET' }, true)
  const banks = useFetch(`/api/bank-list/${env.categoryId}`, { method: 'GET' }, true)
  const offers = useFetch(`/api/offer-list/${env.categoryId}`, { method: 'GET' }, true)
  const keys = Object.keys(state)
  const params = keys.map(key => `${key}=${encodeURIComponent(state[key])}`).join('&')
  const [perform, setPerform] = useState(false)
  const base64Pdf = useFetch(`/api/generatePDFContract?${params}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }, perform)

  const setInputValue = (key, value) => {
    let newSate = Object.assign({}, state);

    if (!value || value === '') {
      delete newSate[key]
      if (key === 'customerLegalFunctionId') sessionStorage.removeItem('legalFunction')
    } else {
      newSate[key] = value
    }

    setState(newSate)
  }

  const setOffer = (offer) => {
    let newSate = Object.assign({}, state);
    newSate.offerArticleId = offer.oid.articleId
    newSate.offerBankId = offer.oid.bankId
    newSate.offerContractId = offer.oid.contractId
    newSate.offerLimit = offer.oid.limit
    newSate.rateCode = offer.rateCode
    setState(newSate)
  }

  const submit = (e) => {
    e.preventDefault()
    message.destroy()
    setPerform(true)
  }

  useEffect(() => {
    if (perform && base64Pdf.status === 'success' && base64Pdf.data.statusMessage === 'OK') {
      sessionStorage.setItem('register-form-data', JSON.stringify(state))
      sessionStorage.setItem('function', customerFunctions.data.functions.find(customerFunction => customerFunction.id === state.customerFunctionId).name)
      if (state.customerLegalFunctionId) sessionStorage.setItem('legalFunction', customerFunctions.data.functions.find(customerFunction => customerFunction.id === state.customerLegalFunctionId).name)
      sessionStorage.setItem('bank', banks.data.banks.find(bank => bank.id === state.bankId).name)
      const offer = offers.data.offers.find(offer => offer.oid.articleId === state.offerArticleId)
      sessionStorage.setItem('offer', `${offer ? offer.description : ''} - Jusqu'à ${offer ? offer.oid.limit : ''} SIREN - ${offer ? offer.price : ''} € H.T.`)

      history.push('/contrat')
    }
    if (perform && base64Pdf.status === 'success' && base64Pdf.data.statusMessage !== 'OK') {
      message.error(base64Pdf.data.statusCode === 300 ?
        base64Pdf.data.statusMessage.split('>')[1]
        :
        base64Pdf.data.statusMessage, 2.5
      )
      setPerform(false)
    }
    if (perform && base64Pdf.status === 'error') {
      message.error('Erreur interne, merci de contacter le service clients', 2.5)
      setPerform(false)
    }
  }, [perform, base64Pdf, state, customerFunctions, banks, offers, history])

  return (
    <Row>
      <Col span={22} offset={1}>
        <Row>
          <Col xs={{span: 24}} sm={{span: 24}} md={{span: 6}}>
            <AnchorMenu />
          </Col>
          <Col
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 18}}
            style={{
              paddingLeft: '20px'
            }}
          >
            <form onSubmit={(e) => submit(e)}>
              <FormSectionTitle id='company-section'>Coordonnées professionnelles</FormSectionTitle>
                <Row>
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 12}}>
                    <Label>Société</Label>
                    <VerifiedInput
                      defaultValue={state.companyName}
                      maxLength='36'
                      handleChange={value => setInputValue('companyName', value)}
                    />
                  </Col>
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 6}}>
                    <Label>SIREN</Label>
                    <VerifiedInput
                      defaultValue={state.companySIREN}
                      verified={isValidSIREN(state.companySIREN)}
                      maxLength='9'
                      handleChange={value => setInputValue('companySIREN', value)}
                    />
                  </Col>
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 6}}>
                    <Tooltip
                      placement='top'
                      title={'Le NIC (Numéro Interne de Classement) est un identifiant numérique composé de 5 chiffres placés à la fin du SIRET'}
                    >
                      <Label style={{cursor: 'pointer'}}>
                        <span>NIC (optionnel) </span>
                        <InfoCircleFilled />
                      </Label>
                    </Tooltip>
                    <VerifiedInput
                      defaultValue={state.companyNIC}
                      verified={isValidNIC(state.companyNIC)}
                      maxLength='5'
                      handleChange={value => setInputValue('companyNIC', value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 12}}>
                    <Label>Adresse</Label>
                    <VerifiedInput
                      defaultValue={state.companyAddress}
                      maxLength='36'
                      handleChange={value => setInputValue('companyAddress', value)}
                    />
                  </Col>
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 6}}>
                    <Label>Code postal</Label>
                    <VerifiedInput
                      defaultValue={state.companyPostCode}
                      verified={isValidPostalCode(state.companyPostCode)}
                      maxLength='5'
                      handleChange={value => setInputValue('companyPostCode', value)}
                    />
                  </Col>
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 6}}>
                    <Label>Ville</Label>
                    <VerifiedInput
                      defaultValue={state.companyTown}
                      maxLength='36'
                      handleChange={value => setInputValue('companyTown', value)}
                    />
                  </Col>
                </Row>
              <FormSectionTitle id='user-section'>Coordonnées personnelles</FormSectionTitle>
              <FormSectionSubTitle id='account-user-sub-section'>Utilisateur du compte</FormSectionSubTitle>
              <Row>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 4}}>
                  <Label>Civilité</Label>
                  <SelectWrapper>
                    <Select
                      className={state.customerCivility && state.customerCivility !== '' ? 'selected' : ''}
                      defaultValue={state.customerCivility}
                      onChange={value => setInputValue('customerCivility', value)}
                      allowClear
                      dropdownMatchSelectWidth={false}
                    >
                      {civilities.data && civilities.data.civilities ?
                        civilities.data.civilities.map((civility, i) =>
                          (<Select.Option key={i} value={civility}>{civility}</Select.Option>)
                        )
                        :
                        null
                      }
                    </Select>
                  </SelectWrapper>
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 6}}>
                  <Label>Nom</Label>
                  <VerifiedInput
                    defaultValue={state.customerLastname}
                    maxLength='60'
                    handleChange={value => setInputValue('customerLastname', value)}
                  />
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 6}}>
                  <Label>Prénom</Label>
                  <VerifiedInput
                    defaultValue={state.customerFirstname}
                    maxLength='60'
                    handleChange={value => setInputValue('customerFirstname', value)}
                  />
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 8}}>
                  <Label>Fonction</Label>
                  <SelectWrapper>
                    <Select
                      className={state.customerFunctionId && state.customerFunctionId !== '' ? 'selected' : ''}
                      defaultValue={state.customerFunctionId}
                      onChange={value => setInputValue('customerFunctionId', value)}
                      allowClear
                      dropdownMatchSelectWidth={false}
                    >
                      {customerFunctions.data && customerFunctions.data.functions ?
                        customerFunctions.data.functions.map((customerFunction, i) =>
                          (<Select.Option key={i} value={customerFunction.id}>{customerFunction.name}</Select.Option>)
                        )
                        :
                        null
                      }
                    </Select>
                  </SelectWrapper>
                </Col>
              </Row>
              <Row>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 10}}>
                  <Label>Email</Label>
                  <VerifiedInput
                    defaultValue={state.customerEmail}
                    verified={isValidEmail(state.customerEmail)}
                    maxLength='200'
                    handleChange={value => setInputValue('customerEmail', value)}
                  />
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 6}}>
                  <Label>Téléphone</Label>
                  <VerifiedInput
                    defaultValue={state.customerPhone}
                    verified={isValidPhoneNumber(state.customerPhone)}
                    maxLength='10'
                    handleChange={value => setInputValue('customerPhone', value)}
                  />
                </Col>
              </Row>
              <FormSectionSubTitle id='legal-owner-sub-section'>Représentant légal (requis si différent de l'utilisateur)</FormSectionSubTitle>
              <Row>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 4}}>
                  <Label>Civilité</Label>
                  <SelectWrapper>
                    <Select
                      className={state.customerLegalCivility && state.customerLegalCivility !== '' ? 'selected' : ''}
                      defaultValue={state.customerLegalCivility}
                      onChange={value => setInputValue('customerLegalCivility', value)}
                      allowClear
                      dropdownMatchSelectWidth={false}
                    >
                      {civilities.data && civilities.data.civilities ?
                        civilities.data.civilities.map((civility, i) =>
                          (<Select.Option key={i} value={civility}>{civility}</Select.Option>)
                        )
                        :
                        null
                      }
                    </Select>
                  </SelectWrapper>
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 6}}>
                  <Label>Nom</Label>
                  <VerifiedInput
                    defaultValue={state.customerLegalLastname}
                    maxLength='60'
                    handleChange={value => setInputValue('customerLegalLastname', value)}
                  />
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 6}}>
                  <Label>Prénom</Label>
                  <VerifiedInput
                    defaultValue={state.customerLegalFirstname}
                    maxLength='60'
                    handleChange={value => setInputValue('customerLegalFirstname', value)}
                  />
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 8}}>
                  <Label>Fonction</Label>
                  <SelectWrapper>
                    <Select
                      className={state.customerLegalFunctionId && state.customerLegalFunctionId !== '' ? 'selected' : ''}
                      defaultValue={state.customerLegalFunctionId}
                      onChange={value => setInputValue('customerLegalFunctionId', value)}
                      allowClear
                      dropdownMatchSelectWidth={false}
                    >
                      {customerFunctions.data && customerFunctions.data.functions ?
                        customerFunctions.data.functions.map((customerFunction, i) =>
                          (<Select.Option key={i} value={customerFunction.id}>{customerFunction.name}</Select.Option>)
                        )
                        :
                        null
                      }
                    </Select>
                  </SelectWrapper>
                </Col>
              </Row>
              <Row>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 10}}>
                  <Tooltip
                    placement='top'
                    title={'L\'email du représentant légal doit être différent de l\'email de l\'utilisateur du compte'}
                  >
                    <Label style={{cursor: 'pointer'}}>
                      <span>Email </span>
                      <InfoCircleFilled />
                    </Label>
                  </Tooltip>
                  <VerifiedInput
                    defaultValue={state.customerLegalEmail}
                    verified={isValidEmail(state.customerLegalEmail) && state.customerLegalEmail !== state.customerEmail}
                    maxLength='200'
                    handleChange={value => setInputValue('customerLegalEmail', value)}
                  />
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 6}}>
                  <Label>Téléphone</Label>
                  <VerifiedInput
                    defaultValue={state.customerLegalPhone}
                    verified={isValidPhoneNumber(state.customerLegalPhone)}
                    maxLength='10'
                    handleChange={value => setInputValue('customerLegalPhone', value)}
                  />
                </Col>
              </Row>
              <FormSectionTitle id='bank-section'>Coordonnées bancaires</FormSectionTitle>
              <Row>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 6}}>
                  <Label>Banque</Label>
                  <SelectWrapper>
                    <Select
                      className={state.bankId && state.bankId !== '' ? 'selected' : ''}
                      defaultValue={state.bankId}
                      onChange={value => setInputValue('bankId', value)}
                      allowClear
                      dropdownMatchSelectWidth={false}
                    >
                      {banks.data && banks.data.banks ?
                        banks.data.banks.map((bank, i) =>
                          (<Select.Option key={i} value={bank.id}>{bank.name}</Select.Option>)
                        )
                        :
                        null
                      }
                    </Select>
                  </SelectWrapper>
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 12}}>
                  <Label>IBAN</Label>
                  <VerifiedInput
                    defaultValue={state.companyIBAN}
                    verified={IBAN.isValid(state.companyIBAN)}
                    handleChange={value => setInputValue('companyIBAN', IBAN.printFormat(value, '-'))}
                  />
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 6}}>
                  <Label>BIC (optionnel)</Label>
                  <VerifiedInput
                    defaultValue={state.companyBIC}
                    maxLength='11'
                    handleChange={value => setInputValue('companyBIC', value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 6}}>
                  <Label>Nom du contact (optionnel)</Label>
                  <VerifiedInput
                    defaultValue={state.bankContactName}
                    maxLength='100'
                    handleChange={value => setInputValue('bankContactName', value)}
                  />
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 6}}>
                  <Label>Identifiant agence (optionnel)</Label>
                  <VerifiedInput
                    defaultValue={state.bankIdentification}
                    maxLength='100'
                    handleChange={value => setInputValue('bankIdentification', value)}
                  />
                </Col>
              </Row>
              <FormSectionTitle id='offer-section'>Configuration de l'offre intuiz zen</FormSectionTitle>
              <Row>
                {offers.data && offers.data.offers ?
                  offers.data.offers.map((offer, i) =>
                    <Col
                      key={offer.oid.articleId}
                      xs={{span: 12}}
                      sm={{span: 12}}
                      md={{span: 12}}
                      lg={{span: 6}}
                    >
                      <Offer
                        className={state.offerArticleId === offer.oid.articleId ? 'selected' : ''}
                        onClick={() => setOffer(offer)}
                      >
                        {state.offerArticleId === offer.oid.articleId ? <OfferChecked /> : null}
                        <OfferName>{offer.description.split(' « ')[0]}</OfferName>
                        <OfferType>{offer.description.split(' « ')[1].split(' »')[0]}</OfferType>
                        <OfferLimit
                          style={offer.oid.limit === 1000 ? {
                            lineHeight: '15px'
                          } : {}}
                        >
                          <span>Jusqu'à </span>
                          <strong>{offer.oid.limit}</strong>
                          <span> SIREN</span>
                          {offer.oid.limit === 1000 ?
                            <div>+ {offer.description.split(' + ')[1]}</div>
                          : null}
                        </OfferLimit>
                        <OfferPrice>{`${offer.price} € H.T.`}</OfferPrice>
                      </Offer>
                    </Col>
                  )
                : null}
              </Row>
              <DividerWrapper>
                <Divider></Divider>
              </DividerWrapper>
              <div style={{textAlign: 'right'}}>
                <Tooltip
                  placement='topRight'
                  title={
                    <SubmitTooltipContent>
                      {required.length || invalid.length ?
                        <>
                          {required.length ?
                            <>
                              <p>Champs requis manquant :</p>
                              <ul>
                                {required.map((field, i) =>
                                  <li key={i}>{field}</li>
                                )}
                              </ul>
                            </>
                          : null}
                          {invalid.length ?
                            <>
                              <p>Formats de champs invalides :</p>
                              <ul>
                                {invalid.map((field, i) =>
                                  <li key={i}>{field}</li>
                                )}
                              </ul>
                            </>
                          : null}
                        </>
                        :
                        'Accéder à l\'étape suivant pour visualiser le récapitulatif de mes informations et des tarifs de mon offre intuiz+ ZEN avant de valider mon inscription'
                      }
                    </SubmitTooltipContent>
                  }
                >
                  <SubmitButton
                    disabled={requiredFields(state).length || invalidFields(state).length ? 'disabled' : ''}
                    size='large'
                    type='primary'
                    onClick={(e) => submit(e)}
                    loading={perform && base64Pdf.status === 'pending' ? true : false}
                  >Poursuivre l'inscription<RightOutlined /></SubmitButton>
                </Tooltip>
              </div>
            </form>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default withRouter(RegisterForm)
