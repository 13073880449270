import React, { useEffect } from 'react'

import ContractStep from '../modules/register/ContractStep'

export default () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <ContractStep />
  )
}
